import request from "@/utils/request";

export enum Api {
    CATEGORY_LIST = "/mall/mall-product-category/list",
    CREATE_CATEGORY = "/mall/mall-product-category/createOrUpdate",
    CREATE_PRODUCT = "/mall/mall-product/createOrUpdate",
    CREATE_PRODUCT_INFO = "/mall/mall-product/createOrUpdateInfo",
    COPY_PRODUCT_INFO = "/mall/mall-product/copy",

    PRODUCT_LIST = "/mall/mall-product/list",
    PRODUCT_UPDATE_STOCK = "/mall/mall-product/updateStock",
    PRODUCT_UPDATE_INITSELL = "/mall/mall-product/updateInitSell",
    PRODUCT_UPDATE_SORT = "/mall/mall-product/updateSort",
    PRODUCT_UPDATE_STATUS = "/mall/mall-product/updateSellStatus",
    PRODUCT_DETAIL = "/mall/mall-product/detail/",
    AGREEMENT_LIST = "/mall/mall-product/listAgreements",
    PRODUCT_TYPE_LIST = "/mall/mall-product/listTypes",
    PRODUCT_BATCH = "/mall/mall-product/batchUpdate"
}

export const getProductCategoryList = (params: any) => request.post(Api.CATEGORY_LIST, params);
export const getAgreementList = () => request.post(Api.AGREEMENT_LIST);
export const createProductCategory = (params: any) => request.post(Api.CREATE_CATEGORY, params);
export const createProduct = (params: any) => request.post(Api.CREATE_PRODUCT, params);
export const copyProduct = (spu: string) => request.post(`${Api.COPY_PRODUCT_INFO}/${spu}`);
export const createProductInfo = (params: any) => request.post(Api.CREATE_PRODUCT_INFO, params);
export const getProductList = (params: any) => request.post(Api.PRODUCT_LIST, params);
export const updateProductStock = (params: any) => request.post(Api.PRODUCT_UPDATE_STOCK, params);
export const updateProductInitSell = (params: any) => request.post(Api.PRODUCT_UPDATE_INITSELL, params);
export const updateProductStatus = (params: any) => request.post(Api.PRODUCT_UPDATE_STATUS, params);
export const updateProductSort = (params: any) => request.post(Api.PRODUCT_UPDATE_SORT, params);
export const getProductDetail = (spu: string) => request.post(`${Api.PRODUCT_DETAIL}${spu}`);
export const getProductTypeList = () => request.post(`${Api.PRODUCT_TYPE_LIST}`);
export const productBatch = (params: any) => request.post(`${Api.PRODUCT_BATCH}`, params);
