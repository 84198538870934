import type { AppRouteType } from "@/router/types";

const finance: AppRouteType[] = [
    {
        path: "/finance",
        name: "Finance",
        component: () => import("@/layouts/index.vue"),
        sort: 4,
        meta: {
            title: "财务管理",
            icon: "List"
        },
        children: [
            {
                path: "distribution",
                name: "DistributionIndex",
                component: () => import("@/views/finance/distribution.vue"),
                meta: {
                    title: "平台分账配置",
                    icon: "Files"
                }
            },
            {
                path: "withdrew",
                name: "WithdrewIndex",
                component: () => import("@/views/finance/withdraw.vue"),
                meta: {
                    title: "提现列表",
                    icon: "Files"
                }
            }
        ]
    }
];

export default finance;
