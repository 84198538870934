import request from "@/utils/request";
import axios from "axios";

import dayjs from "dayjs";
import { useEnv } from "@/hooks";

const { VITE_COS_URL } = useEnv();

export const getNavListApi = (parentKey: string | number) => request.post(`/sys/sysDict/listByParentkey/${parentKey}`);

export const getRegionList = () => {
    const timestamp = dayjs().valueOf();
    return axios.get(`${VITE_COS_URL}system_info/area.json?time=${timestamp}`);
};

export const getExpressTypeList = () => {
    return request.post(`/mall/express/vendors`);
};
