export function useEnv() {
    const { VITE_APP_NAME, VITE_BASE_API, VITE_PUBLIC_PATH, VITE_BASE_UPLOAD_API, VITE_COS_URL, VITE_DOMAIN, MODE } = import.meta.env;

    return {
        VITE_COS_URL,
        MODE,
        VITE_APP_NAME,
        VITE_BASE_API,
        VITE_PUBLIC_PATH,
        VITE_BASE_UPLOAD_API,
        VITE_DOMAIN
    };
}
