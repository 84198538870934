import type { AppRouteType } from "@/router/types";

const afterSale: AppRouteType[] = [
    {
        path: "/after-sale",
        name: "AfterSale",
        component: () => import("@/layouts/index.vue"),
        redirect: "/after-sale/index",
        sort: 3,
        meta: {
            title: "售后管理",
            icon: "Stamp"
        },
        children: [
            {
                path: "index",
                name: "AfterSaleIndex",
                component: () => import("@/views/after-sale/index.vue"),
                meta: {
                    title: "售后列表",
                    icon: "DocumentRemove"
                },
            }
        ]
    }
];

export default afterSale;
