import { isObject, isUndefined, isString } from "./is";

export function uuid(length = 12) {
    return parseInt(
        "x"
            .repeat(length)
            .replace("x", function () {
                return ((Math.random() * 9 + 1) | 0).toString(10);
            })
            .replace(/x/gi, function () {
                return ((Math.random() * 10) | 0).toString(10);
            })
    );
}

/**
 * 排除掉obj里面的key值
 * @param {object} obj
 * @param {Array|string} args
 * @returns {object}
 */
export function omit<T extends Record<string, any>, P extends keyof T>(obj: T, args: string | string[]) {
    if (!args) return obj;
    const newObj = {} as Omit<T, P>;
    const isString = typeof args === "string";
    const keys = Object.keys(obj).filter((item) => {
        if (isString) {
            return item !== args;
        }
        return !(<P[]>args).includes(item as P);
    }) as Exclude<keyof T, P>[];

    keys.forEach((key) => {
        if (obj[key] !== undefined) newObj[key] = obj[key];
    });
    return newObj;
}

/**
 * 深拷贝
 * @param {*} source
 * @returns {*}
 */
export function deepClone(source: any) {
    if (!source && typeof source !== "object") {
        return source;
    }
    const targetObj = source.constructor === Array ? [] : {};
    Object.keys(source).forEach((keys) => {
        if (source[keys] && typeof source[keys] === "object") {
            (targetObj as any)[keys] = deepClone(source[keys]);
        } else {
            (targetObj as any)[keys] = source[keys];
        }
    });
    return targetObj;
}

/**
 * 深度合并
 * @param {*} src
 * @param {*} target
 * @returns {*}
 */
export function deepMerge<T = any>(src: any = {}, target: any = {}): T {
    let key: string;
    for (key in target) {
        src[key] = isObject(target[key]) ? deepMerge(src[key], target[key]) : (src[key] = target[key]);
    }
    return src;
}

export function truncateDecimal(number: number | string, decimalPlaces = 2) {
    const factor = Math.pow(10, decimalPlaces);
    number = !number ? 0 : number;
    return (Math.floor(Number(number) * factor) / factor).toFixed(2);
}
export function formatMoney(number: number | string) {
    number = Number(number).toFixed(2);
    const arr = (number + "").split(".");
    const int = arr[0].split("");
    const fraction = arr[1] || "";
    let r = "";
    int.reverse().forEach(function (v, i) {
        if (i !== 0 && i % 3 === 0) {
            r = v + "," + r;
        } else {
            r = v + r;
        }
    });
    if (fraction) {
        return r + "." + fraction;
    } else {
        return r;
    }
}

export const enumToOptions = (enumKey: any, enumDesc: any) => {
    return Object.entries(enumKey)
        .map(([key, value]) => {
            return {
                value: enumKey[key],
                label: enumDesc[value as typeof enumKey]
            };
        })
        ?.filter((el) => el.label);
};
