import request from "@/utils/request";

export enum Api {
    LOGIN = "/admin/login",
    GET_USER_INFO = "/admin/info"
}

export const login = (params: any) => (
    request.post(Api.LOGIN, params)
)

// 获取当前登录用户信息
export const getUserInfo = () => (
    request.get(Api.GET_USER_INFO)
)
