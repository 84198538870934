// @ts-nocheck

import { getRegionList } from "@/api/common";
import { local } from "./storage";
import { EnumerateEnum } from "@/enums/commonEnum";

export const setRegionData = () => {
  getRegionList().then((res) => {
    const data = res.data;
    local.setItem(EnumerateEnum.yyRegion, data);
  }).catch((err) => {
    local.setItem(EnumerateEnum.yyRegion, []);
  });
}

/**
 * @description 缓存中拿省市区 
 * @returns Promise<Array<any>>
 */
export const getRegionData = () => {
  // 先判断ls 里面有没有 yy-region
  const regionData = local.getItem(EnumerateEnum.yyRegion);
  return new Promise((resolve) => {
    if (regionData) {
      return resolve(regionData);
    }
    getRegionList().then((res) => {
      local.setItem(EnumerateEnum.yyRegion, res.data);
      return resolve(res.data);
    }).catch((err) => {
      return resolve([]);
    });
  })
}

export const getAddressDesc = (address: string) => {
  if (!address) address = "";
  const [ province, city,  district ] = address?.split(",")
  const regionData = local.getItem(EnumerateEnum.yyRegion);
  let str = "";
  const _province = regionData.find((el) => el.value == province);
  if (_province) {
    str += _province.label;
    if (_province.children) {
      const _city = _province.children?.find((el) => el.value == city);
      if (_city) {
        str += _city.label;
        const _district = _city.children?.find((el) => el.value == district);
        if (_district) {
          str += _district.label;
        }
      }
    }
  }
  return str;
}