import type { AppRouteType } from "@/router/types";

const product: AppRouteType[] = [
    {
        path: "/product",
        name: "Product",
        component: () => import("@/layouts/index.vue"),
        redirect: "/product/index",
        sort: 1,
        meta: {
            title: "商品管理",
            icon: "GoodsFilled",
        },
        children: [
            {
                path: "index",
                name: "ProductIndex",
                component: () => import("@/views/product/index.vue"),
                meta: {
                    title: "商品列表",
                    icon: "Handbag"
                }
            },
            {
                path: "add",
                name: "ProductAdd",
                component: () => import("@/views/product/add.vue"),
                meta: {
                    title: "发布商品",
                    hidden: true
                }
            },
            {
                path: "category",
                name: "ProductCategory",
                component: () => import("@/views/product/category.vue"),
                meta: {
                    title: "商品分类",
                    icon: "PriceTag"
                }
            }
        ]
    }
];

export default product;
