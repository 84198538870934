import type { AppRouteType } from "@/router/types";

const order: AppRouteType[] = [
    {
        path: "/order",
        name: "Order",
        component: () => import("@/layouts/index.vue"),
        redirect: "/product/index",
        sort: 2,
        meta: {
            title: "订单管理",
            icon: "List",
        },
        children: [
            {
                path: "index",
                name: "OrderList",
                component: () => import("@/views/order/index.vue"),
                meta: {
                    title: "订单管理",
                    icon: "Files"
                }
            },
            {
                path: "detail",
                name: "OrderDetail",
                component: () => import("@/views/order/detail.vue"),
                meta: {
                    title: "订单详情",
                    hidden: true
                }
            },
            {
                path: "refund-detail",
                name: "OrderRefundDetail",
                component: () => import("@/views/order/refundDetail.vue"),
                meta: {
                    title: "退款详情",
                    hidden: true
                }
            },
        ]
    }
];

export default order;
