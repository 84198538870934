import type { AppRouteType } from "@/router/types";

const marketing: AppRouteType[] = [
    {
        path: "/marketing",
        name: "Marketing",
        component: () => import("@/layouts/index.vue"),
        redirect: "/marketing/teamBuy",
        sort: 4,
        meta: {
            title: "营销管理",
            icon: "Briefcase",
        },
        children: [
            {
                path: "teamBuy",
                name: "MarketingTeamBuy",
                component: () => import("@/views/marketing/teamBuy.vue"),
                meta: {
                    title: "拼团活动",
                    icon: "Reading"
                }
            },
            {
                path: "addTeamBuy",
                name: "MarketingAddTeamBuy",
                component: () => import("@/views/marketing/addTeamBuyActivity.vue"),
                meta: {
                    title: "新增拼团活动",
                    icon: "Monitor",
                    hidden: true
                }
            },
            {
                path: "teamBought",
                name: "MarketingTeamBought",
                component: () => import("@/views/marketing/teamBoughtList.vue"),
                meta: {
                    title: "拼团列表",
                    icon: "Operation",
                }
            },
        ]
    }
];

export default marketing;
