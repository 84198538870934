import { setRegionData } from "@/utils/cityCode";
import { getProductTypeList } from "@/api/product";
import { getNavListApi, getExpressTypeList } from "@/api/common";

// 暂时写在这里，后续优化
export const productTypeListOptions: Array<{ name: string; code: string }> = [];
const initProductTypeListOptions = () => {
    getProductTypeList().then((res: IResponse<Array<{ name: string; code: string }>>) => {
        Object.assign(productTypeListOptions, res.data);
    });
};

export const storeTypeListOptions: Array<{ dictValue: string; id: string | number }> = [];
const initStoreTypeListOptions = () => {
    getNavListApi("store_type").then((res: IResponse<Array<{ name: string; code: string }>>) => {
        Object.assign(storeTypeListOptions, res.data);
    });
};

export const expressTypeListOptions: Array<any> = [];
const initExpressTypeListOptions = () => {
    getExpressTypeList().then((res: IResponse<Array<{ name: string; code: string }>>) => {
        Object.assign(expressTypeListOptions, res.data);
    });
};

export default function () {
    setRegionData();
    initProductTypeListOptions();
    initStoreTypeListOptions();
    initExpressTypeListOptions();
}
