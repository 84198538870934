import type { AppRouteType } from "@/router/types";



const home: AppRouteType[] = [
    {
        path: "/:pathMatch(.*)",
        name: "Global",
        redirect: "/404",
        meta: {
            title: "重定向",
            hidden: true
        },
    },
    {
        path: "/home",
        name: "Home",
        component: () => import("@/layouts/index.vue"),
        redirect: "/home/index",
        meta: {
            title: "首页",
            hideChildren: true,
            icon: "HomeFilled"
        },
        children: [
            {
                path: "/home/index",
                name: "HomeIndex",
                component: () => import("@/views/home/index.vue"),
            }
        ]
    }
];

export default home;
