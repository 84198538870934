// @ts-nocheck
import { getStyle } from "element-plus/es/utils/dom/index";
import type { Directive, App, DirectiveBinding } from "vue";

const showTip: Directive = {
    mounted(el: Element, binding: DirectiveBinding<any>, vnode: any) {
        const tooltipNode = vnode.children.find((childCmpt) => childCmpt.component?.type.name == "ElTooltip");
        if (tooltipNode) {
            // let { content } = tooltipNode.props;
            el.addEventListener("mouseenter", () => {
                tooltipNode.component.props.disabled = true;
                const range = document.createRange();
                range.setStart(el, 0);
                range.setEnd(el, el.childNodes.length);
                const rangeClientRect = range.getBoundingClientRect();
                const rangeWidth = Math.round(rangeClientRect.width);
                const rangeHeight = Math.round(rangeClientRect.height);
                const padding = (parseInt(getStyle(el, "paddingLeft"), 10) || 0) + (parseInt(getStyle(el, "paddingRight"), 10) || 0);

                if (rangeWidth + padding > el.offsetWidth || el.scrollWidth > el.offsetWidth || rangeHeight > el.clientHeight) {
                    tooltipNode.component.props.disabled = false;
                }
            });
        }
    }
};
export const setupShowTipDirective = (app: App) => {
    app.directive("showTip", showTip);
    console.log("showTip");
};
export default showTip;
